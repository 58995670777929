import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud Google Home",
  "path": "/Motion_Detection/INSTAR_Cloud/Google_Home/",
  "dateChanged": "2022-05-06",
  "author": "Mike Polinowski",
  "excerpt": "The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.",
  "image": "MD_SearchThumb_Cloud_Alexa.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Recordings.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Google Home – INSTAR Wiki' dateChanged='2022-05-06' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.' image='/images/Search/MD_SearchThumb_Cloud.png' twitter='/images/Search/MD_SearchThumb_Cloud.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Google_Home/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Google_Home/' crumbLabel="Google Home" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h1>
    <h2 {...{
      "id": "how-to-link-your-instar-cloud-action",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-link-your-instar-cloud-action",
        "aria-label": "how to link your instar cloud action permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to Link your INSTAR Cloud Action`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://support.google.com/chromecast/answer/7071794?hl=en&co=GENIE.Platform%3DAndroid"
      }}>{`Google Home app`}</a>{` helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.`}</p>
    <p><strong parentName="p">{`Step 01`}</strong>{`: Once you have logged into the cloud, go to the Cameras tab and add a new camera there if your camera has not already been created there. After the camera is created, go to Edit for the respective camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50fc38729fa8aa30a88f96e3f0103411/5b795/INSTAR_Google_Home_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABPUlEQVQY022OS07CUBiF7yYMJs5JZMiILZjQgaMu5U5lHy7FxBUYNCGKDx7ltvRBKe1Pe3v/+zSCEgZ+OTmDc/6c/GTy/GS0stbqA8aYo7v/UNahseicbgubTcg44e8pNPW+aRoAOPnx2p7hrDU/ckZL0+QWa3I/zh4W4LSyf/PGmPNHTiuHQBnrdL1R80ddhUTCzmJzNxp5nkcpTdMEUWZ5mGdrKRXnnFI6HA4ppdttjlLmRbzNM2mcMpYUu3JfN4PBgBDS7Xan06kQYrmYr1ZMSgUA170eIaTf7zPG2radLVcsZCi41oqUZck5932/0+l4nhcEASJG0ZotZwBVta9vvZurywvf97M0FUKEjEXBvARApUlVVZzzOI4ZYwAghJCIXEhMXj8m42QDuPksoi/Y179Vixi9vAVBIew3z1dp49sNmLQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50fc38729fa8aa30a88f96e3f0103411/e4706/INSTAR_Google_Home_01.avif 230w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/d1af7/INSTAR_Google_Home_01.avif 460w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/7f308/INSTAR_Google_Home_01.avif 920w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/e1c99/INSTAR_Google_Home_01.avif 1380w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/b52ec/INSTAR_Google_Home_01.avif 1455w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50fc38729fa8aa30a88f96e3f0103411/a0b58/INSTAR_Google_Home_01.webp 230w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/bc10c/INSTAR_Google_Home_01.webp 460w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/966d8/INSTAR_Google_Home_01.webp 920w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/445df/INSTAR_Google_Home_01.webp 1380w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/bda17/INSTAR_Google_Home_01.webp 1455w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50fc38729fa8aa30a88f96e3f0103411/81c8e/INSTAR_Google_Home_01.png 230w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/08a84/INSTAR_Google_Home_01.png 460w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/c0255/INSTAR_Google_Home_01.png 920w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/b1001/INSTAR_Google_Home_01.png 1380w", "/en/static/50fc38729fa8aa30a88f96e3f0103411/5b795/INSTAR_Google_Home_01.png 1455w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50fc38729fa8aa30a88f96e3f0103411/c0255/INSTAR_Google_Home_01.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 02`}</strong>{`: You need to find P2P information of your camera, and fill them here. You have to chosed Google Home option. Only you allow it, then you can use these information on Google Actions. After you chosed, then save it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b3ccfda5e9362684f54f81b094dbb54c/4c5bd/INSTAR_Google_Home_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABO0lEQVQoz5WS3UrDMBhA+8peCL6EFz6NtyI6fyYqAxlWKduSpoaSmnb5z5d+kg5hd9sOIXcn5yNJwRgry/J7QggBADgxHgIRi67rqqpqmqaqKkpp0zSccyFEOoLCWiuldM6ZCaWU1toYEw+R5b7vGWN930spcY+DY2d5q1Tbtruscy6EEGMMIYZ/dp0AKQBEgLynFAGyrAbJ6hoArLXGGO/DOCbEtD9CAkjeYPQYQ17BJ2dTgmLVpk3NCdm0bdt1XUrRRSxrQzZrQkhN6aA16p4vyuXslc7v6MsjX7zZr0/0vtBu1CZHnXPe+9wZUVkwJl+bNSZEwBgcbwdaa7IyrHakguUNuqHA08jPi1bEh8sk10fL44jTx9g/pTiliETBM/dz7p9+/IcIx8ppsq/X9vxeXszk2e3v1fv2D5sgtZ7mdeMjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3ccfda5e9362684f54f81b094dbb54c/e4706/INSTAR_Google_Home_02.avif 230w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/d1af7/INSTAR_Google_Home_02.avif 460w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/7f308/INSTAR_Google_Home_02.avif 920w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/e1c99/INSTAR_Google_Home_02.avif 1380w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/0cc0f/INSTAR_Google_Home_02.avif 1435w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b3ccfda5e9362684f54f81b094dbb54c/a0b58/INSTAR_Google_Home_02.webp 230w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/bc10c/INSTAR_Google_Home_02.webp 460w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/966d8/INSTAR_Google_Home_02.webp 920w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/445df/INSTAR_Google_Home_02.webp 1380w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/1bd9f/INSTAR_Google_Home_02.webp 1435w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3ccfda5e9362684f54f81b094dbb54c/81c8e/INSTAR_Google_Home_02.png 230w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/08a84/INSTAR_Google_Home_02.png 460w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/c0255/INSTAR_Google_Home_02.png 920w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/b1001/INSTAR_Google_Home_02.png 1380w", "/en/static/b3ccfda5e9362684f54f81b094dbb54c/4c5bd/INSTAR_Google_Home_02.png 1435w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b3ccfda5e9362684f54f81b094dbb54c/c0255/INSTAR_Google_Home_02.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 03`}</strong>{`: Download Home App From Play Store.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/a13c9/INSTAR_Google_Home_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABaElEQVQoz2Mo7toftmduau+GqsTdW+t2H6/edyxn2benb///////37//eAFDdtMbhy3755ct74449XDD5Jt996/Nv/zt3juiNMd1PbGfeDbo4LqQnMvLi65vyb83s+Pcu9dfidD7n6Fgw+vgWbcWZa8pq7928MDX1d1Pl5989eDdD6I0Rxx+Y7nqftSRve5zL5ZUPMhMvR2x6emDH3//////9z8hzUFHHlnsfzht0/Wo1Q8KO+7GZd1J3PPy/s9/RGkOv/zC5crbrEu37Hc88y176JNwK2DNk4dEak7deCp+7z3btm3Gq844TVsdk3epYdX1x5//EuXnhKOHs/Zcjd5z23TDA/fi44nR16Z33nn/njjNU64dbN5yP/j0B4PV75zjH6b739le8PT3mz/geCakOfvspuiSUx7TnxrOeOkRcTst4Pb+3Mf/X/8mSnPQ0fUp6YeiM+7b5z+IDrtTEHL3QN4TIjUDACEAaj7hfaRqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/e4706/INSTAR_Google_Home_03.avif 230w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/d1af7/INSTAR_Google_Home_03.avif 460w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/7f308/INSTAR_Google_Home_03.avif 920w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/fcbe8/INSTAR_Google_Home_03.avif 1178w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/a0b58/INSTAR_Google_Home_03.webp 230w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/bc10c/INSTAR_Google_Home_03.webp 460w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/966d8/INSTAR_Google_Home_03.webp 920w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/3126b/INSTAR_Google_Home_03.webp 1178w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/81c8e/INSTAR_Google_Home_03.png 230w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/08a84/INSTAR_Google_Home_03.png 460w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/c0255/INSTAR_Google_Home_03.png 920w", "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/a13c9/INSTAR_Google_Home_03.png 1178w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95b137b4065ec1fe8b40ea7b6999bc2b/c0255/INSTAR_Google_Home_03.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 04`}</strong>{`: Open Home App, it will ask you to login Google Account. If you never used it, you may need to created a new home. Here we created a home `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`INSTAR`}</code>{` as an example. Then you can click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Settings`}</code>{` to set up your home.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/5c744/INSTAR_Google_Home_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABCklEQVQoz5WRy07DQAxF+9N8CjvWrFmwQ+ID2LBAVQVIRdCUZtI0D2fsmUlmfFHTIrGANtyN5ceRLd8ZUvLMrVh2glEhBCLyzuOcZiF771dv0buoeigNMQK4WTw95msA6bv+C5zaWtsaKe3nUlJVxX5607WNdwD0xOafiTGmqipM1oxGtUT9ENm5qq47a4lIRFT1DCwizGwtD06iE24bIWLmEMJ5+BiHHnlmPzNfGOQZ+n7S2cfoPZpdQ511HnWJ4P8Dx4hiE8ym3xqUOUa3JsOHbxdlVdUn3fkDDl7mi+fX5UfSyfDhpcLd/cNyuaZyW7rVNYZy7OokmGl3dftyeRcKU/D8Aj6bAn8Bg1n7IM8BrFoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56c4b2085da1db8b3c2d3f715fea5aae/e4706/INSTAR_Google_Home_04.avif 230w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/d1af7/INSTAR_Google_Home_04.avif 460w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/7f308/INSTAR_Google_Home_04.avif 920w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/b866d/INSTAR_Google_Home_04.avif 1206w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/56c4b2085da1db8b3c2d3f715fea5aae/a0b58/INSTAR_Google_Home_04.webp 230w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/bc10c/INSTAR_Google_Home_04.webp 460w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/966d8/INSTAR_Google_Home_04.webp 920w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/b732a/INSTAR_Google_Home_04.webp 1206w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56c4b2085da1db8b3c2d3f715fea5aae/81c8e/INSTAR_Google_Home_04.png 230w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/08a84/INSTAR_Google_Home_04.png 460w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/c0255/INSTAR_Google_Home_04.png 920w", "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/5c744/INSTAR_Google_Home_04.png 1206w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/56c4b2085da1db8b3c2d3f715fea5aae/c0255/INSTAR_Google_Home_04.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Then you can see below page, then choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`set up device`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62ab8888047ef03076481f334d92aef7/8d68c/INSTAR_Google_Home_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAtUlEQVQY043PwW7EIAwE0P3/3+xum5AQCDbGsQlTtZV6LJ3r6Gk0DwCmWqTqdQEYY1ApldnNeu/4Mw/vvbYW4r7HYw1bFQHQ79FUzWyCL6b9+Xa8vzrTXXlIhQqauDZzn+D8CuG5SdxoW2+paF8SKXo67L4nuOVyLlHCwutCy0fPCZVxZmey6efm45SuUkWEiNwNGADcff6ZdETuTCXlnFJS1Z/i286wXCNXZyqFiJl/i/8sfwJCadYSukF/xgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62ab8888047ef03076481f334d92aef7/e4706/INSTAR_Google_Home_05.avif 230w", "/en/static/62ab8888047ef03076481f334d92aef7/d1af7/INSTAR_Google_Home_05.avif 460w", "/en/static/62ab8888047ef03076481f334d92aef7/7f308/INSTAR_Google_Home_05.avif 920w", "/en/static/62ab8888047ef03076481f334d92aef7/e83e9/INSTAR_Google_Home_05.avif 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62ab8888047ef03076481f334d92aef7/a0b58/INSTAR_Google_Home_05.webp 230w", "/en/static/62ab8888047ef03076481f334d92aef7/bc10c/INSTAR_Google_Home_05.webp 460w", "/en/static/62ab8888047ef03076481f334d92aef7/966d8/INSTAR_Google_Home_05.webp 920w", "/en/static/62ab8888047ef03076481f334d92aef7/5dcdf/INSTAR_Google_Home_05.webp 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62ab8888047ef03076481f334d92aef7/81c8e/INSTAR_Google_Home_05.png 230w", "/en/static/62ab8888047ef03076481f334d92aef7/08a84/INSTAR_Google_Home_05.png 460w", "/en/static/62ab8888047ef03076481f334d92aef7/c0255/INSTAR_Google_Home_05.png 920w", "/en/static/62ab8888047ef03076481f334d92aef7/8d68c/INSTAR_Google_Home_05.png 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62ab8888047ef03076481f334d92aef7/c0255/INSTAR_Google_Home_05.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 06`}</strong>{`: Choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Works with Google`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c81667f412de4fe101f6f00a0d4b7618/610c0/INSTAR_Google_Home_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABFElEQVQoz4WR2Y7DIAxF+///OEuzAU1wCAQDZvEo1WheOmmuLL9cH1/JvjFzLTXGiCmmlPgpIkLEEEKtlc91Y2ZEbK29erVWIrqAG3N7oVtr/258gZ9DANAPvRBiGMd+GBDD07qCvUfvcXPWbJu17mi/cn9XOIWJcs4lxrRorWGdF60BjuslKqVcwMyccw4hvM85SS7s3D6Ok1IP7z0e8t77nPM1LCwbh6OQk1TdMI5S9ZOYpJLzUq7+3Dhi0LMeenjW0t0f31+bmGiFFvEtjL5ZQ5vZHzLoeVcyW8MpcPBHacUln8O7dZ5Whx9df5/kZ9d3QilYwTqbKMPCmc7hgGy3uttsVjJABtIKQS9k4LD0/Cb5BxTD+Ri8XvXkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c81667f412de4fe101f6f00a0d4b7618/e4706/INSTAR_Google_Home_06.avif 230w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/d1af7/INSTAR_Google_Home_06.avif 460w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/7f308/INSTAR_Google_Home_06.avif 920w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/42d56/INSTAR_Google_Home_06.avif 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c81667f412de4fe101f6f00a0d4b7618/a0b58/INSTAR_Google_Home_06.webp 230w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/bc10c/INSTAR_Google_Home_06.webp 460w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/966d8/INSTAR_Google_Home_06.webp 920w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/a8acc/INSTAR_Google_Home_06.webp 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c81667f412de4fe101f6f00a0d4b7618/81c8e/INSTAR_Google_Home_06.png 230w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/08a84/INSTAR_Google_Home_06.png 460w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/c0255/INSTAR_Google_Home_06.png 920w", "/en/static/c81667f412de4fe101f6f00a0d4b7618/610c0/INSTAR_Google_Home_06.png 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c81667f412de4fe101f6f00a0d4b7618/c0255/INSTAR_Google_Home_06.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 07`}</strong>{`:You may click search button, and input `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`INSTAR Cloud`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b9d159ed006a75a0b9d76df762c8272/1320e/INSTAR_Google_Home_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAzklEQVQY032P22rDQAxE8/9f14eGmCY0tDZZB9fdqyxpLWkLhhYKcQ7zOIdhDmYmIgCQc661tg0A8CGUnBFRVdsOBzMjIlWdpsl7P8+z995aaymU1xe6dCvzriwiiMjMMcYQQowxpcS1cil4d/h1J6Jn8jiOfd/fnPMx1loREXApAEBUEAHAzB7Lqioiqqay5vPJv53CucPPq4noFhHZlW2jtYbL8t0d0/UCH+91vP019sx/nxGRmLclWUXqL8y8uywizrlhGHLOUB7w5PMPWGbStK9Jh+QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9d159ed006a75a0b9d76df762c8272/e4706/INSTAR_Google_Home_07.avif 230w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/d1af7/INSTAR_Google_Home_07.avif 460w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/7f308/INSTAR_Google_Home_07.avif 920w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/0e2fe/INSTAR_Google_Home_07.avif 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b9d159ed006a75a0b9d76df762c8272/a0b58/INSTAR_Google_Home_07.webp 230w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/bc10c/INSTAR_Google_Home_07.webp 460w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/966d8/INSTAR_Google_Home_07.webp 920w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/e3770/INSTAR_Google_Home_07.webp 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9d159ed006a75a0b9d76df762c8272/81c8e/INSTAR_Google_Home_07.png 230w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/08a84/INSTAR_Google_Home_07.png 460w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/c0255/INSTAR_Google_Home_07.png 920w", "/en/static/5b9d159ed006a75a0b9d76df762c8272/1320e/INSTAR_Google_Home_07.png 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b9d159ed006a75a0b9d76df762c8272/c0255/INSTAR_Google_Home_07.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 08`}</strong>{`: Then it requires Account Linking. You just need to click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Continue`}</code>{`. If you don't want to link it, just click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Cancel`}</code>{`, but in this case, you can't use INSTAR Cloud Action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/89048/INSTAR_Google_Home_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOklEQVQoz43Ry27cMAwF0Pz/nwVdTVB00okbxEYSS6IeHj2sF0UWHXRVFOPcBRcEDhe8D6WUeV7Wdf24RUoJoOd5sdbyUR5SSqfT6Xw+X15enp/P8zxP03S5XKZpyjkfYCLqvSNi761WbK3VUnrvtVZEPMB/Bg1mRvtI4YmYB42KFW/LL2CmWNnbN0zvoe6vepYekPBLGInfIZnr7vP4sewNq/Km1EZEx7gP/j5JME7Z/O2nZcaYixaiXd0RJsLBAjbrts1tJYcYU0y73xyWcoBba0oq56wxWimlAIwxALBtVwUQY7yHa61CSq31KsTn5woASoEQYl1XKWQI4R7uvacYYwjOOX9LCMF7v/kQU4z7fvCwvXH7pxcaaHTSrmvgku9hGzHkwUx/w8wD6/KmpyX8euWcbuf+U9tvZ+n0lCzyJjYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9be9b42a360e11defd3f08da3fa7ad2c/e4706/INSTAR_Google_Home_08.avif 230w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/d1af7/INSTAR_Google_Home_08.avif 460w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/7f308/INSTAR_Google_Home_08.avif 920w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/4f997/INSTAR_Google_Home_08.avif 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9be9b42a360e11defd3f08da3fa7ad2c/a0b58/INSTAR_Google_Home_08.webp 230w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/bc10c/INSTAR_Google_Home_08.webp 460w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/966d8/INSTAR_Google_Home_08.webp 920w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/a3537/INSTAR_Google_Home_08.webp 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9be9b42a360e11defd3f08da3fa7ad2c/81c8e/INSTAR_Google_Home_08.png 230w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/08a84/INSTAR_Google_Home_08.png 460w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/c0255/INSTAR_Google_Home_08.png 920w", "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/89048/INSTAR_Google_Home_08.png 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9be9b42a360e11defd3f08da3fa7ad2c/c0255/INSTAR_Google_Home_08.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 09`}</strong>{`: It will redirect to INSTAR Cloud Login page, you need to use INSTAR Cloud System account to link with your camera with INSTAR Cloud Action. If you still don't have account, you can go to website `}<a parentName="p" {...{
        "href": "https://instar.cloud.de"
      }}>{`instar.cloud.de`}</a>{` to register with free.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/3c492/INSTAR_Google_Home_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABBElEQVQoz6WRz0rEMBCH96X1Zo++wZ704kHoE+gbBHYRiiLYnszJshKapvkzSdrMyDZYPNh1wY+BTA4f/GZmQ0SICABSyhACzYQQpJTGGABARFph8/OD36SU6Aw29A9mGSlna9uWc940TVmWUsqc5Q95yVnXNWOMc15VlbX2LFlKKYTIG7LWGmO01saYZQurMiJ2XZedYQYAvPcxRiIahkEIcUruVe9DdD448A4gjGOcUpxSGCcHfrnfL3JK6XD4TKOn0VGwhDFfLRfidCp2QrRaPb6b7Yu+fT3W3Zu+mZvt88A+YJ58NfbxuW/sBeuLvSp26vpJXe1UsVeXrH/gjojSivwFJ218Z6rAaccAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/e4706/INSTAR_Google_Home_09.avif 230w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/d1af7/INSTAR_Google_Home_09.avif 460w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/7f308/INSTAR_Google_Home_09.avif 920w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/a2baf/INSTAR_Google_Home_09.avif 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/a0b58/INSTAR_Google_Home_09.webp 230w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/bc10c/INSTAR_Google_Home_09.webp 460w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/966d8/INSTAR_Google_Home_09.webp 920w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/063bf/INSTAR_Google_Home_09.webp 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/81c8e/INSTAR_Google_Home_09.png 230w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/08a84/INSTAR_Google_Home_09.png 460w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/c0255/INSTAR_Google_Home_09.png 920w", "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/3c492/INSTAR_Google_Home_09.png 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb9222fa30cbb462d0eb1d2772fbeb0f/c0255/INSTAR_Google_Home_09.png",
              "alt": "Google Home - How to Link your INSTAR Cloud Action",
              "title": "Google Home - How to Link your INSTAR Cloud Action",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If there is no any errors, then you linked your account successfully. Just go back to home page of your APP. If you have created some cameras in Cloud System. Then they will be discovered there.`}</p>
    <h2 {...{
      "id": "whats-commands-can-be-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-commands-can-be-used",
        "aria-label": "whats commands can be used permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's commands can be used?`}</h2>
    <ul>
      <li parentName="ul">{`Play your live videos:`}
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "text"
        }}><pre parentName="div" {...{
            "className": "language-text"
          }}><code parentName="pre" {...{
              "className": "language-text"
            }}>{`  * _"Hey Google, show [camera name]."_
  * _"OK Google, what's on [camera name]?"_
  * _"Hey Google, can you play [camera name] on [display name]?"_`}</code></pre></div>
      </li>
      <li parentName="ul">{`Stop your live videos:`}
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "text"
        }}><pre parentName="div" {...{
            "className": "language-text"
          }}><code parentName="pre" {...{
              "className": "language-text"
            }}>{`  * _"OK Google, stop."_`}</code></pre></div>
      </li>
    </ul>
    <h2 {...{
      "id": "qa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qa",
        "aria-label": "qa permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Q&A`}</h2>
    <h3 {...{
      "id": "error-message-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#error-message-1",
        "aria-label": "error message 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Error message 1:`}</h3>
    <p><strong parentName="p">{`Phrase`}</strong>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`authFailure`}</code>{`
`}<strong parentName="p">{`Message`}</strong>{`: I can't seem to reach `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`device(s)`}</code>{`. Try checking the app to make sure your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`device/devices is/are`}</code>{` fully set up.`}</p>
    <p><strong parentName="p">{`Possible reasons`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`User deleted this camera on INSTAR Cloud, but it is still on Google Home App.`}</li>
      <li parentName="ol">{`User doesn't add p2p information and camera username&password in INSTAR Cloud System.
3.User added p2p information and camera username&password, but he doesn't authorized Google to use it.`}</li>
    </ol>
    <h3 {...{
      "id": "error-message-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#error-message-2",
        "aria-label": "error message 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Error message 2:`}</h3>
    <p><strong parentName="p">{`Message`}</strong>{`: Ok, streaming of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[your camera name]`}</code>{`, but live video doesn't work, only show "Smart Home camera" on your Google device display.`}</p>
    <p><strong parentName="p">{`Possible reasons`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Your camera is offline.`}</li>
      <li parentName="ol">{`Your p2p information and camera username&password is incorrect.`}</li>
    </ol>
    <h3 {...{
      "id": "error-message-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#error-message-3",
        "aria-label": "error message 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Error message 3:`}</h3>
    <p><strong parentName="p">{`Phrase`}</strong>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`relinkRequired`}</code>{`
`}<strong parentName="p">{`Message`}</strong>{`: Sorry, it looks like something went wrong with your account. Please use your Google Home or Assistant App to re-link `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`device(s)`}</code>{`.`}</p>
    <p><strong parentName="p">{`Possible reasons`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`It may your devices lost connections with our INSTAR Cloud action, you can reconnect with INSTAR Cloud action again.`}</li>
    </ol>
    <h3 {...{
      "id": "error-message-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#error-message-4",
        "aria-label": "error message 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Error message 4:`}</h3>
    <p><strong parentName="p">{`Message`}</strong>{`: Sorry, can't connect INSTAR Cloud.`}</p>
    <p><strong parentName="p">{`Possible reasons`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`It may your devices lost connections with our INSTAR Cloud action, you can reconnect with INSTAR Cloud action again. If you meet any errors, you'd better to make sure your p2p information and camera username&password is correct, and to make sure you also give permissions to Google to use your credentials. If everything is correct and your camera is also online, then you may try to reconnect INSTAR Cloud action again. If everything you tried, but it still doesn't work, you may contact us to help you check.`}</li>
    </ol>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      